.bkg {
    max-height: 100%;
    background: #cde0e7;
}
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 150px;
    background: #c9e1ec;
}
.loginWrapper {
    border-radius: 10px 10px 10px 10px;
    background: #dbd8d8;
    padding: 30px;
    width: 90%;
    max-width: 500px;
    /*max-width: 568px;*/
    position: relative;
    padding-top: 30px;
    padding-bottom: 10px;
    box-shadow: 0px 5px 15px 2px rgba(0,0,0,0.3);
    text-align: center;
}
.textFields {
    position: relative;
    justify-content: left;
    text-align: left;
    font-size: 35px;
    font-family: #000000;
    padding-top: 0px;
    padding-bottom: 25px;
}
.logo {
    width: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0;
    padding-bottom: 50px;
}
.logo img {
    max-height: 30%;
    width: 250px;
}
.lButton {
    padding-top: 80px;
    justify-content: center;
    align-items: center;
}


button[type=button].login {
    width: 100%;
    height: 55px;
    background-color: #cfad6c;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: #000;
    font-size: 35px;
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 50px;
}
button[type=button].login:hover {
    background-color: #d1c2a5;
}
button[type=button].login:active {
    transform: scale(0.95);
}